import {ref, onMounted} from 'vue'
import {fetchPost} from '@/api/post'

export default function usePostGateway(slug) {
    const post = ref(null);
    const getPost = async () => {
        post.value = await fetchPost(slug);
    }

    onMounted(getPost);

    return {
        post,
    };
}